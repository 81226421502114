<template>
  <div class="ui-parent-section">
    <UISection>
      <template #title>
        <div>Кнопки</div>
        <Button>dddd</Button>
      </template>

      <div>
        <el-row class="m-b-10">
          <el-button>Default</el-button>
          <el-button type="primary">Primary</el-button>
          <el-button type="success">Success</el-button>
          <el-button type="info">Info</el-button>
          <el-button type="warning">Warning</el-button>
          <el-button type="danger">Danger</el-button>
        </el-row>

        <el-row class="m-b-10">
          <el-button plain>Plain</el-button>
          <el-button type="primary" plain>Primary</el-button>
          <el-button type="success" plain>Success</el-button>
          <el-button type="info" plain>Info</el-button>
          <el-button type="warning" plain>Warning</el-button>
          <el-button type="danger" plain>Danger</el-button>
        </el-row>

        <el-row class="m-b-10">
          <el-button round>Round</el-button>
          <el-button type="primary" round>Primary</el-button>
          <el-button type="success" round>Success</el-button>
          <el-button type="info" round>Info</el-button>
          <el-button type="warning" round>Warning</el-button>
          <el-button type="danger" round>Danger</el-button>
        </el-row>

        <el-row class="m-b-10">
          <el-button icon="el-icon-search" circle />
          <el-button type="primary" icon="el-icon-edit" circle />
          <el-button type="success" icon="el-icon-check" circle />
          <el-button type="info" icon="el-icon-message" circle />
          <el-button type="warning" icon="el-icon-star-off" circle />
          <el-button type="danger" icon="el-icon-delete" circle />
        </el-row>

        <el-row class="m-b-10">
          <el-button disabled>Default</el-button>
          <el-button type="primary" disabled>Primary</el-button>
          <el-button type="success" disabled>Success</el-button>
          <el-button type="info" disabled>Info</el-button>
          <el-button type="warning" disabled>Warning</el-button>
          <el-button type="danger" disabled>Danger</el-button>
        </el-row>

        <el-row class="m-b-10">
          <el-button plain disabled>Plain</el-button>
          <el-button type="primary" plain disabled>Primary</el-button>
          <el-button type="success" plain disabled>Success</el-button>
          <el-button type="info" plain disabled>Info</el-button>
          <el-button type="warning" plain disabled>Warning</el-button>
          <el-button type="danger" plain disabled>Danger</el-button>
        </el-row>
      </div>
    </UISection>
  </div>
</template>

<script>
import UISection from '@/views/ui/components/UISection'
export default {
  name: 'Buttons',
  components: { UISection },
}
</script>

<style lang="sass">
.color-box
  width: 180px
  background: #FFFFFF
  border: 1px solid #DCDCDC
  padding: 8px
  border-radius: 8px

  &__wrap
    display: flex
    justify-content: flex-start
    align-items: flex-start
    flex-wrap: wrap
    gap: 10px

  &__color
    width: 100%
    padding-top: 100%
    background: darkred
    border-radius: 8px
    margin-bottom: 8px
    border: 1px solid #DCDCDC
</style>
