var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ui-parent-section" },
    [
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", [_vm._v("Кнопки")]),
                  _c("Button", [_vm._v("dddd")]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { staticClass: "m-b-10" },
                [
                  _c("el-button", [_vm._v("Default")]),
                  _c("el-button", { attrs: { type: "primary" } }, [
                    _vm._v("Primary"),
                  ]),
                  _c("el-button", { attrs: { type: "success" } }, [
                    _vm._v("Success"),
                  ]),
                  _c("el-button", { attrs: { type: "info" } }, [
                    _vm._v("Info"),
                  ]),
                  _c("el-button", { attrs: { type: "warning" } }, [
                    _vm._v("Warning"),
                  ]),
                  _c("el-button", { attrs: { type: "danger" } }, [
                    _vm._v("Danger"),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "m-b-10" },
                [
                  _c("el-button", { attrs: { plain: "" } }, [_vm._v("Plain")]),
                  _c("el-button", { attrs: { type: "primary", plain: "" } }, [
                    _vm._v("Primary"),
                  ]),
                  _c("el-button", { attrs: { type: "success", plain: "" } }, [
                    _vm._v("Success"),
                  ]),
                  _c("el-button", { attrs: { type: "info", plain: "" } }, [
                    _vm._v("Info"),
                  ]),
                  _c("el-button", { attrs: { type: "warning", plain: "" } }, [
                    _vm._v("Warning"),
                  ]),
                  _c("el-button", { attrs: { type: "danger", plain: "" } }, [
                    _vm._v("Danger"),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "m-b-10" },
                [
                  _c("el-button", { attrs: { round: "" } }, [_vm._v("Round")]),
                  _c("el-button", { attrs: { type: "primary", round: "" } }, [
                    _vm._v("Primary"),
                  ]),
                  _c("el-button", { attrs: { type: "success", round: "" } }, [
                    _vm._v("Success"),
                  ]),
                  _c("el-button", { attrs: { type: "info", round: "" } }, [
                    _vm._v("Info"),
                  ]),
                  _c("el-button", { attrs: { type: "warning", round: "" } }, [
                    _vm._v("Warning"),
                  ]),
                  _c("el-button", { attrs: { type: "danger", round: "" } }, [
                    _vm._v("Danger"),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "m-b-10" },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-search", circle: "" },
                  }),
                  _c("el-button", {
                    attrs: {
                      type: "primary",
                      icon: "el-icon-edit",
                      circle: "",
                    },
                  }),
                  _c("el-button", {
                    attrs: {
                      type: "success",
                      icon: "el-icon-check",
                      circle: "",
                    },
                  }),
                  _c("el-button", {
                    attrs: {
                      type: "info",
                      icon: "el-icon-message",
                      circle: "",
                    },
                  }),
                  _c("el-button", {
                    attrs: {
                      type: "warning",
                      icon: "el-icon-star-off",
                      circle: "",
                    },
                  }),
                  _c("el-button", {
                    attrs: {
                      type: "danger",
                      icon: "el-icon-delete",
                      circle: "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "m-b-10" },
                [
                  _c("el-button", { attrs: { disabled: "" } }, [
                    _vm._v("Default"),
                  ]),
                  _c(
                    "el-button",
                    { attrs: { type: "primary", disabled: "" } },
                    [_vm._v("Primary")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "success", disabled: "" } },
                    [_vm._v("Success")]
                  ),
                  _c("el-button", { attrs: { type: "info", disabled: "" } }, [
                    _vm._v("Info"),
                  ]),
                  _c(
                    "el-button",
                    { attrs: { type: "warning", disabled: "" } },
                    [_vm._v("Warning")]
                  ),
                  _c("el-button", { attrs: { type: "danger", disabled: "" } }, [
                    _vm._v("Danger"),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "m-b-10" },
                [
                  _c("el-button", { attrs: { plain: "", disabled: "" } }, [
                    _vm._v("Plain"),
                  ]),
                  _c(
                    "el-button",
                    { attrs: { type: "primary", plain: "", disabled: "" } },
                    [_vm._v("Primary")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "success", plain: "", disabled: "" } },
                    [_vm._v("Success")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "info", plain: "", disabled: "" } },
                    [_vm._v("Info")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "warning", plain: "", disabled: "" } },
                    [_vm._v("Warning")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "danger", plain: "", disabled: "" } },
                    [_vm._v("Danger")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }